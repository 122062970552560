import request from '@/utils/request'

export function getAccessoriesParamList(params) {
    return request({
        url: '/accessoriesParameters/page',
        method: 'GET',
        params,
    })
}

export function getAccessoriesParamListNew(params) {
    return request({
        url: '/accessoriesParameters/listAccessoriesParameters',
        method: 'GET',
        params,
    })
}

export function createAccessoriesParam (data) {
    return request({
        url: data.id ? `/accessoriesParameters/update/${data.id}` : `/accessoriesParameters/create`,
        method: 'POST',
        data
    })
}

export function deleteAccessoriesParam(data) {
    return request({
        url: '/accessoriesParameters/delete',
        method: 'POST',
        data,
    })
}








