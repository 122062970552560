import request from '@/utils/request'

export function getProductParamList(params) {
    return request({
        url: '/productParameters/page',
        method: 'GET',
        params,
    })
}

export function getProductParamListNew(params) {
    return request({
        url: '/productParameters/selectPage',
        method: 'GET',
        params,
    })
}

export function createProductParam (data) {
    return request({
        url: data.id ? `/productParameters/update/${data.id}` : `/productParameters/create`,
        method: 'POST',
        data
    })
}

export function deleteProductParam(data) {
    return request({
        url: '/productParameters/delete',
        method: 'POST',
        data,
    })
}








