<template>
  <div class="full-card has-table">
    <div class="handle-area">
      <a-button type="primary" icon="plus" @click="openCreate('create')" v-has-permission="['addProduct']">新增产品</a-button>
      <div class="filter margin-l20" @click="filterShow = true"></div>
    </div>
    <a-card>
      <a-table
          :columns="columns"
          :data-source="list"
          :loading="tableLoading"
          :pagination="pagination"
          rowKey="id"
          bordered
          @change="pageChange"
          :scroll="{ y: 'calc(100% - 55px)'}"
      >
        <template slot="id" slot-scope="text, record">
          <img src="@/assets/image/common/edit.png" alt="" @click="openCreate('edit', record)" v-has-permission="['editProduct']">
          <img src="@/assets/image/common/link.png" alt="" class="margin-l20" @click="openLinkParam(record)" v-has-permission="['bindProductParam']">
          <img src="@/assets/image/common/aAndC.png" alt="" class="margin-l20" @click="openAAndC(record)" v-has-permission="['bindAccesories']">
          <img src="@/assets/image/common/files.png" alt="" class="margin-l20" @click="openFiles(record)" v-has-permission="['editProduct']">
          <img src="@/assets/image/common/delete.png" alt="" class="margin-l20" @click="delItem(record)" v-has-permission="['deleteProduct']">
        </template>
      </a-table>
    </a-card>
    <a-drawer
        title="过滤器"
        placement="right"
        :visible="filterShow"
        @close="filterShow = false"
        width="500px"
        class="drawer"
    >
      <div class="drawer-cot">
        <a-form-model
            labelAlign="right"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 18 }"
            :model="filterForm"
            class="drawer-form"
        >
          <a-form-model-item :wrapperCol="{ span: 24 }">
            <a-input-search placeholder="产品名称/ID" v-model="filterForm.name" @search="onFilter" />
          </a-form-model-item>

        </a-form-model>
        <div class="drawer-handle">
          <a-button type="primary" icon="search" @click="onFilter">筛选</a-button>
          <a-button style="margin-left: 10px;" icon="reload" @click="onReset">重置</a-button>
        </div>
      </div>
    </a-drawer>
    <a-drawer
        :title="`${ form.id ? '编辑' : '新增'}产品` "
        placement="right"
        :visible="createShow"
        @close="closeCreate"
        width="500px"
        class="drawer"
        v-if="createShow"
    >
      <div class="drawer-cot">
        <a-form-model
            ref="form"
            labelAlign="right"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 18 }"
            :model="form"
            :rules="rules"
            class="drawer-form"
        >
          <a-row>
            <a-form-model-item label="产品名称" prop="productName">
              <a-input placeholder="请输入产品名称" v-model="form.productName" />
            </a-form-model-item>
            <a-form-model-item label="产品ID" prop="productId">
              <a-input placeholder="请输入产品ID" v-model="form.productId" />
            </a-form-model-item>
            <a-form-model-item label="保养周期（天）" prop="maintenanceCycle">
              <a-input placeholder="请输入保养周期" type="number" v-model="form.maintenanceCycle" />
            </a-form-model-item>
            <a-form-model-item label="备注" prop="remark">
              <a-textarea placeholder="请输入备注" v-model="form.remark" :auto-size="{ minRows: 3, maxRows: 5 }" />
            </a-form-model-item>
          </a-row>
        </a-form-model>
        <div class="drawer-handle">
          <a-button type="primary" icon="check" @click="onSubmit">确定</a-button>
          <a-button icon="close" class="margin-l20" @click="closeCreate">取消</a-button>
        </div>
      </div>

    </a-drawer>
    <a-drawer
        title="关联产品参数"
        placement="right"
        :visible="linkShow"
        @close="linkShow = false"
        width="500px"
        class="drawer"
        v-if="linkShow"
    >
      <div class="drawer-cot">
        <div class="drawer-form">
          <div class="display-f justify-center margin-t10">
            <a-input-search placeholder="产品参数名称/ID" v-model="paramsForm.name" @search="getProductParam" style="width: 400px"/>
          </div>
          <a-table
              :row-selection="rowSelection"
              :columns="paramColumns"
              :data-source="productParamList"
              :loading="tableLoading"
              :pagination="false"
              rowKey="id"
              bordered
              class="margin-t30"
          >
            <template slot="id" slot-scope="text, record">
              <a-input placeholder="请输入数值" v-model="record.staticParameterValue"/>
            </template>
          </a-table>
        </div>
        <div class="drawer-handle">
          <a-button type="primary" icon="check" @click="linkSubmit">关联</a-button>
          <a-button class="margin-l20" icon="close" @click="linkShow = false">取消</a-button>
        </div>
      </div>
    </a-drawer>
    <a-drawer
        title="关联配件/耗材"
        placement="right"
        :visible="aAndCShow"
        @close="aAndCShow = false"
        width="500px"
        class="drawer"
        v-if="aAndCShow"
    >
      <div class="drawer-cot">
        <div class="drawer-form">
          <div class="display-f justify-center margin-t10">
            <a-input-search placeholder="配件/耗材名称或ID" v-model="aAndCForm.name" style="width: 400px" @search="getAccessoriesList" />
          </div>
          <a-table
              :row-selection="aAndCSelection"
              :columns="aAndCColumns"
              :data-source="aAndCList"
              :loading="tableLoading"
              :pagination="false"
              rowKey="id"
              bordered
              class="margin-t30"
          >
          </a-table>
        </div>
        <div class="drawer-handle">
          <a-button type="primary" icon="check" @click="aAndCSubmit">关联</a-button>
          <a-button class="margin-l20" icon="close" @click="aAndCShow = false">取消</a-button>
        </div>
      </div>
    </a-drawer>
    <a-drawer
        title="产品文件"
        placement="right"
        :visible="filesShow"
        @close="filesShow = false"
        width="500px"
        class="drawer"
        v-if="filesShow"
    >
      <div class="drawer-cot">
        <a-row class="padding-30 drawer-form">
          <div class="display-f align-items-c justify-between padding-lr20 margin-b20" v-for="(item, index) in filesList.fileInfos" :key="item.id">
            <span>{{ item.fileName }}</span>
            <img src="@/assets/image/common/download.png" alt="" class="cursor"  @click="downloadFiles(item)">
          </div>
          <div class="display-f align-items-c justify-between padding-lr20 margin-b20" v-for="(item, index) in filesList.sharedFileLibraries" :key="item.id">
            <span>{{ item.fileName }}</span>
            <img src="@/assets/image/common/download.png" alt="" class="cursor"  @click="downloadFiles(item)">
          </div>
        </a-row>
        <div class="drawer-handle">
          <a-button type="primary" icon="check" style="width: 150px" @click="downloadAll">一键下载全部</a-button>
          <a-button icon="close" class="margin-l20" @click="filesShow = false">取消</a-button>
        </div>
      </div>

    </a-drawer>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import {
  getProductList,
  createProduct,
  deleteProduct,
  getProductFiles,
  bindParam,
  downloadAll, bindAAndC
} from '../../api/product/product'
import { getProductParamList } from '../../api/product/productParam'
import { getAccessoriesParamList } from '../../api/product/accessoriesParam'
import { getAccessoriesList } from '../../api/product/accessories'

export default {
  name: 'productList',
  data () {
    return {
      columns: [
        {
          title: '产品名称',
          dataIndex: 'productName'
        },
        {
          title: '产品ID',
          dataIndex: 'productId'
        },
        {
          title: '保养周期（天）',
          dataIndex: 'maintenanceCycle'
        },
        {
          title: '最近修改时间',
          dataIndex: 'updateTime'
        },
        {
          title: '修改人',
          dataIndex: 'modifier'
        },
        {
          title: '备注',
          dataIndex: 'remark'
        },
        {
          title: '操作',
          dataIndex: 'id',
          scopedSlots: { customRender: 'id' }
        }
      ],
      list: [],
      total: 0,
      createShow: false,
      filterShow: false,
      linkShow: false,
      filesShow: false,
      filterForm: {
        pageNum: 1,
        pageSize: 10
      },
      form: {},
      page: 1,
      roleList: [],
      tableLoading: true,
      pagination: {
        total: 0,
        pageSize: 10
      },
      staffShow: false,
      customerShow: false,
      rules: {
        productName: [
          { required: true, message: '请输入产品名称', trigger: 'blur' }
        ],
        productId: [
          { required: true, message: '请输入产品ID', trigger: 'blur' }
        ]
      },
      filesList: {},
      productParamList: [],
      paramsForm: {},
      paramColumns: [
        {
          title: '设备参数名称',
          dataIndex: 'productParametersName'
        },
        {
          title: '数据单位',
          dataIndex: 'dataUnit'
        },
        {
          title: '静态参数赋值',
          dataIndex: 'id',
          scopedSlots: { customRender: 'id' }
        },
      ],
      bindParamList: [],
      aAndCShow: false,
      aAndCList: [],
      aAndCForm: {},
      aAndCColumns: [
        {
          title: '配件/耗材名称',
          dataIndex: 'productAccessoriesName'
        }
      ],
      bindAAndCList: [],
      selectedId: '',
      paramSelectedRowKeys: [],
      aAndCSelectedRowKeys: [],
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    getList () {
      return new Promise( (resolve) => {
        this.tableLoading = true
        getProductList(this.filterForm).then(res => {
          this.list = res.data.list
          this.pagination.total = res.data.total
          this.tableLoading = false
          resolve(res.data)
        })
      })
    },
    pageChange (pages) {
      this.filterForm.pageNum = pages.current
      this.getList()
    },
    openCreate (type, item) {
      if (item) this.form = JSON.parse(JSON.stringify(item))
      this.createShow = true
    },
    closeCreate () {
      this.form = {}
      this.createShow = false
    },
    delItem (item) {
      this.$confirm({
        title: '提示',
        content: '确认删除这条数据？',
        centered: true,
        onOk: () => {
          deleteProduct({
            ids: [item.id]
          }).then(() => {
            this.$message.success('删除成功！')
            this.getList()
          })
        }
      })
    },
    onSubmit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          createProduct(this.form).then(() => {
            this.$message.success('操作成功！')
            this.getList()
            this.closeCreate()
          })
        }
      })
    },
    onFilter () {
      this.filterForm.pageNum = 1
      this.getList().then(() => {
        this.filterShow = false
      })
    },
    onReset () {
      this.filterForm = {
        pageNum: 1,
        pageSize: 10
      }
      this.getList()
    },
    openFiles (item) {
      this.selectedId = item.id
      getProductFiles({
        id: item.id
      }).then(res => {
        this.filesList = res.data
        this.filesShow = true
      })
    },
    openLinkParam (item) {
      this.selectedId = item.id
      this.getProductParam()
    },
    getProductParam () {
      getProductParamList({
        pageNum: 1,
        pageSize: 10000,
        productId: this.selectedId,
        name: this.paramsForm.name
      }).then(res => {
        this.productParamList = res.data.list
        let arr = []
        res.data.list.forEach(item => {
          if (item.binded) {
            arr.push(item.id)
          }
        })
        this.paramSelectedRowKeys = arr
        this.linkShow = true
      })
    },
    linkSubmit () {
      bindParam({
        productId: this.selectedId,
        bindParameterInfos: this.bindParamList
      }).then(() => {
        this.$message.success('关联成功！')
        this.linkShow = false
      })
    },
    openAAndC (item) {
      this.selectedId = item.id
      this.getAccessoriesList()
    },
    getAccessoriesList () {
      getAccessoriesList({
        pageNum: 1,
        pageSize: 10000,
        productId: this.selectedId,
        name: this.aAndCForm.name
      }).then(res => {
        this.aAndCList = res.data.list
        let arr = []
        res.data.list.forEach(item => {
          if (item.binded) {
            arr.push(item.id)
          }
        })
        this.aAndCSelectedRowKeys = arr
        this.aAndCShow = true
      })
    },
    aAndCSubmit () {
      bindAAndC({
        accessoriesIds: this.aAndCSelectedRowKeys,
        productId: this.selectedId
      }).then(() => {
        this.$message.success('绑定成功！')
        this.aAndCShow = false
      })
    },
    downloadFiles (item) {
      window.open('http://project.xteknology.com/upload/' + item.fileUrl)
    },
    downloadAll () {
      downloadAll({
        id: this.selectedId
      }).then(res => {
        this.downloadFiles({fileUrl: res.data})
      })
    }
  },
  computed: {
    ...mapState({
      // 动态主路由
      authority: state => state.user.authority,
    }),
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          selectedRows.forEach(item => {
            item.parameterId = item.id
          })
          this.bindParamList = selectedRows
          this.paramSelectedRowKeys = selectedRowKeys
        },
        selectedRowKeys: this.paramSelectedRowKeys
      };
    },
    aAndCSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          this.aAndCSelectedRowKeys = selectedRowKeys
        },
        selectedRowKeys: this.aAndCSelectedRowKeys
      };
    }
  },
}
</script>

<style scoped>

</style>
