import request from '@/utils/request'

export function getAccessoriesList(params) {
    return request({
        url: '/accessories/page',
        method: 'GET',
        params,
    })
}

export function createAccessories (data) {
    return request({
        url: data.id ? `/accessories/update/${data.id}` : `/accessories/create`,
        method: 'POST',
        data
    })
}

export function deleteAccessories(data) {
    return request({
        url: '/accessories/delete',
        method: 'POST',
        data,
    })
}

export function bindAAndCParam(data) {
    return request({
        url: '/accessories/bindParameters',
        method: 'POST',
        data,
    })
}








